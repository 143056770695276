<template>
    <div>
        <b-card no-body class="mb-2 p-1">
            <b-row>
                <b-col cols="3">
                    <router-link :to="{name: 'PlanningCreate'}" class="btn btn-success">
                        <b-icon-plus scale="1.2"/>
                    </router-link>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button variant="primary" @click="load">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-table
            hover
            responsive
            head-variant="dark"
            :items="items"
            :fields="fields"
            @row-dblclicked="toForm"
        >
            <template #cell(actions)="row">
                <b-button
                    v-show="!row.item.deleted_at"
                    @click="toForm(row.item)"
                    size="sm"
                    variant="warning"
                >
                    <b-icon-pencil-square/>
                </b-button>
                <b-button
                    v-show="!row.item.deleted_at"
                    @click="destroy(row.item)"
                    size="sm"
                    variant="danger"
                >
                    <b-icon-trash-fill/>
                </b-button>
                <b-button
                    v-show="row.item.deleted_at"
                    @click="restore(row.item)"
                    size="sm"
                    variant="light"
                >
                    <b-icon-arrow-return-left/>
                </b-button>

            </template>
        </b-table>
        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="load"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {PLANNING_SANITARY_INSPECTIONS_ENDPOINT} from '@utils/endpoints';
import moment from 'moment';

export default {
    name: 'Index',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: 'Наименование',
                },
                {
                    key: 'department',
                    label: 'Подразделение',
                    formatter(value) {
                        return value ? value.name : null;
                    }
                },
                {
                    key: 'started_at',
                    label: 'Дата начала',
                    formatter(value) {
                        return value ? moment(value).format('DD.MM.YYYY') : null;
                    }
                },
                {
                    key: 'ended_at',
                    label: 'Дата окончания',
                    formatter(value) {
                        return value ? moment(value).format('DD.MM.YYYY') : null;
                    }
                },
                {
                    key: 'actions',
                    label: 'Действия',
                },
            ],
            items: [],
            meta: {
                current_page: 1,
                total_rows: 0,
                per_page: 25,
            }
        }
    },
    methods: {
        load() {
            const params = {
                page: this.meta.current_page,
                filters: {
                    with_trashed: true,
                },
            }

            this.$http.get(PLANNING_SANITARY_INSPECTIONS_ENDPOINT, {params: params})
                .then((response) => {
                    this.items = response.data.data.data;

                    this.meta = {
                        total_rows: response.data.data.total,
                        current_page: response.data.data.current_page,
                        per_page: response.data.data.per_page,
                    }
                });
        },
        toForm(item) {
            if (!item.deleted_at) {
                this.$router.push({
                    name: 'PlanningEdit',
                    params: {
                        id: item.id
                    }
                });
            }
        },
        destroy(item) {
            this.$http.delete(`${PLANNING_SANITARY_INSPECTIONS_ENDPOINT}/${item.id}`).then(this.load);
        },
        restore(item) {
            this.$http.patch(`${PLANNING_SANITARY_INSPECTIONS_ENDPOINT}/${item.id}/restore`).then(this.load);
        }
    },
    mounted() {
        this.load();
    },

}
</script>
